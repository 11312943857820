<template>
  <div class="main-content">
    <h1>俄罗斯站选品表翻译</h1>
    <!-- Excel 文件列表 -->
    <ul class="file-list">
      <div v-if="excelFiles.length === 0">
        点击下方按钮添加文件
      </div>
      <li v-else v-for="(file, index) in excelFiles" :key="index">
        <span>{{ file.name }}</span>
        <button class="delete-btn" @click="removeFile(index)">删除</button>
      </li>
    </ul>

    <!-- 按钮组 -->
    <div class="button-group">
      <div class="button-with-label">
        <button class="action-button" @click="triggerFileInput">1. 点击添加文件</button>
        <span class="label-text">必选</span>
      </div>
      <div class="button-with-label">
        <button class="action-button" @click="triggerOutputFileInput">2. 选择输出文件</button>
        <span class="label-text">非必选</span>
      </div>
      <div class="button-with-label">
        <div v-if="outputFile" class="output-file-name">
          输出文件：{{ outputFile.name }}
        </div>
      </div>
    </div>

    <button 
      class="start-button" 
      @click="startProcessing" 
      :disabled="isProcessing"
      :class="{ 'processing': isProcessing }"
    >
      {{ isProcessing ? '翻译中' : '开始处理' }}
    </button>

    <!-- Replace the existing template-buttons div with this -->
    <div class="template-links">
      <a href="#" class="template-link" @click.prevent="downloadTemplate">下载模板</a>
      <a href="#" class="template-link" @click.prevent="triggerTemplateUpload">上传模板</a>
    </div>

    <!-- Add this new hidden input for template upload -->
    <input
      type="file"
      ref="templateInput"
      @change="handleTemplateUpload"
      accept=".xlsx"
      style="display: none"
    >

    <!-- 隐藏的文件输入元素 -->
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      multiple
      accept=".xlsx,.xls"
      style="display: none"
    >
    <input
      type="file"
      ref="outputFileInput"
      @change="handleOutputFileChange"
      accept=".xlsx,.xls"
      style="display: none"
    >
  </div>

  <Modal :show="showModal" title="请勿重复提交文件" @cancel="closeModal" @confirm="confirmDelete">
    <ul>
      <li v-for="(file, index) in duplicateFiles" :key="index">{{ file }}</li>
    </ul>
  </Modal>
</template>

<script>
import Modal from './AlertModal.vue'

export default {
  components: {
    Modal
  },
  data() {
    return {
      excelFiles: [],
      outputFile: null,
      showModal: false,
      duplicateFiles: [],
      isProcessing: false,
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    triggerOutputFileInput() {
      this.$refs.outputFileInput.click()
    },
    handleFileChange(event) {
      const files = event.target.files
      if (files) {
        const newFiles = Array.from(files)
        this.duplicateFiles = []

        newFiles.forEach(file => {
          if (this.excelFiles.some(existingFile => existingFile.name === file.name)) {
            this.duplicateFiles.push(file.name)
          } else {
            this.excelFiles.push(file)
          }
        })

        if (this.duplicateFiles.length > 0) {
          this.showModal = true
        }
      }
      event.target.value = ''
    },
    handleOutputFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.outputFile = file
      }
      event.target.value = ''
    },
    startProcessing() {
      if (this.excelFiles.length === 0) {
        alert('请先添加文件')
        return
      }

      this.isProcessing = true

      const formData = new FormData()
      this.excelFiles.forEach(file => {
        formData.append('files', file)
      })
      if (this.outputFile) {
        formData.append('output_file', this.outputFile)
      }

      // 确定下载文件名
      let downloadFileName = this.outputFile ? this.outputFile.name : this.excelFiles[0].name
      downloadFileName = downloadFileName.replace(/\.[^/.]+$/, "") + "_translated.xlsx"

      fetch('/api/process-excel/', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            try {
              const data = JSON.parse(text);
              throw new Error(data.error || `HTTP error! status: ${response.status}`);
            } catch (e) {
              throw new Error(`HTTP error! status: ${response.status}. Response: ${text}`);
            }
          });
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = downloadFileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        alert('翻译成功，正在下载翻译后的文件')
      })
      .catch((error) => {
        console.error('Error:', error)
        alert('文件处理失败：' + error.message)
      })
      .finally(() => {
        this.isProcessing = false
      })
    },
    removeFile(index) {
      this.excelFiles.splice(index, 1)
    },
    closeModal() {
      this.showModal = false
      this.duplicateFiles = []
    },
    confirmDelete() {
      this.closeModal()
    },
    downloadTemplate() {
      // 使用相对路径
      fetch('/api/download-template/', {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'TranslationConfigurationExcel.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('模板下载失败：' + error.message);
      });
    },
    triggerTemplateUpload() {
      this.$refs.templateInput.click();
    },
    handleTemplateUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('template', file);

        fetch('/api/upload-template/', {
          method: 'POST',
          body: formData,
          credentials: 'include',
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          alert('模板上传成功');
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('模板上传失败：' + error.message);
        });
      }
      event.target.value = '';
    },
  }
}
</script>

<style scoped>
body {
  background-color: #F3F3F3;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.main-content {
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 1200px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content h1 {
  margin-top: 30px;
}

.file-list {
  list-style-type: none;
  padding: 10px 20px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 400px;
  overflow-y: auto;
  width: 800px;
}

.file-list li {
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-list div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #999;           
  text-align: center; 
}

.button-group {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button-with-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  justify-content: flex-start;
}

.action-button {
  width: 200px;
  padding: 10px 0;
  background-color: #618DFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 500px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0052D9;
}

.label-text {
  margin-left: 10px;
  color: #666;
  font-size: 0.9em;
}

.start-button {
  width: 200px;
  height: 45px;
  padding: 10px 0;
  background-color: #0052D9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  transition: background-color 0.3s;
}

.start-button:hover:not(:disabled) {
  background-color: #003CAB;
}

.start-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.start-button.processing {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delete-btn{
  background-color: #D54941;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 10px;
}

.output-file-name {
  color: #666;
  font-size: 0.9em;
  margin-left: 0;
  text-align: left;
  margin-left: 500px;
}

/* Replace the existing template-buttons and template-button styles with these */
.template-links {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.template-link {
  color: #0052D9;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 14px;
}

.template-link:hover {
  color: #003CAB;
}
</style>